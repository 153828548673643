import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/angular/standalone';
import { add, basket, swapHorizontal, wallet } from 'ionicons/icons';

@Component({
    selector: 'bv-main-page',
    templateUrl: './main-page.component.html',
    styleUrl: './main-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, IonContent, IonList, IonItem, IonIcon, IonLabel],
})
export class MainPageComponent {
    links = [
        { title: 'Mijn Portefeuille', subtitle: 'Een overzicht van mijn bonnen', icon: wallet, route: 'wallet' },
        { title: 'Kopen', subtitle: 'Koop een Hasseltbon voor jou of als geschenk', icon: basket, route: 'buy' },
        {
            title: 'Bon Toevoegen',
            subtitle: 'Voeg een aangekochte bon toe aan je portefeuille',
            icon: add,
            route: 'add-voucher',
        },
        {
            title: 'Historiek',
            subtitle: 'Een overzicht van jouw bestellingen en transactie',
            icon: swapHorizontal,
            route: 'history',
        },
    ];
}
