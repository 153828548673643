import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import localeNlBe from '@angular/common/locales/nl-BE';
import {
    enableProdMode,
    ErrorHandler,
    inject,
    LOCALE_ID,
    provideAppInitializer,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
    NavigationError,
    provideRouter,
    Router,
    RouteReuseStrategy,
    TitleStrategy,
    withComponentInputBinding,
    withNavigationErrorHandler,
    withRouterConfig,
} from '@angular/router';
import { AlertController, IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { BASE_PATH, Configuration } from '@mbp/mbp-brainlane-vouchers-api-client';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import * as Sentry from '@sentry/angular';

import { BrainlaneVouchersEffects } from './app/brainlane-vouchers.effects';
import { vouchersFeature } from './app/brainlane-vouchers.reducer';
import { BrainlaneVouchersAppComponent } from './app/brainlane-vouchers-app.component';
import { backIcon } from './app/components/back-icon';
import MbpTitleStrategy from './app/mbp-title-strategy';
import routes from './app/routes';
import { environment } from './environments/environment';

registerLocaleData(localeNlBe);

Sentry.init({
    dsn: 'https://e243d6d3d3c2142ea6790a39d7c04b10@o398318.ingest.us.sentry.io/4508127114821632',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: environment.env,
    enabled: environment.production,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function initApp(sentryTraceService: Sentry.TraceService) {
    // The order-finished page can be shown in browser, so don't wait until this times out to start the application.
    setTimeout(async () => {
        try {
            const mbpClient = environment.mbpClient;
            await mbpClient.connect();
            await Promise.all([mbpClient.ui.setTitle('Hasseltbonnen'), mbpClient.ui.setStatusLoading(false)]);
        } catch (err) {
            console.error(err);
        }
    });
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(BrainlaneVouchersAppComponent, {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideHttpClient(withFetch()),
        provideRouter(
            routes,
            withRouterConfig({ paramsInheritanceStrategy: 'always' }),
            withComponentInputBinding(),
            // Should only happen on web platform after a new deployment
            withNavigationErrorHandler(async (error: NavigationError) => {
                if (error.error instanceof TypeError && error.error.message.includes('.js')) {
                    const alertController = inject(AlertController);
                    const alert = await alertController.create({
                        header: 'Update beschikbaar',
                        message:
                            'De applicatie is bijgewerkt en u moet de Hasseltbonnen functionaliteit opnieuw openen om door te gaan.',
                        buttons: [{ text: 'Ok' }],
                    });
                    await alert.present();
                    await alert.onDidDismiss();
                    // go back until the webview is closed
                    setInterval(() => {
                        environment.mbpClient.navigation.back();
                    }, 50);
                    return;
                }
                console.error(error);
                return;
            }),
        ),
        provideStore({
            [vouchersFeature.name]: vouchersFeature.reducer,
        }),
        provideEffects(BrainlaneVouchersEffects),
        provideIonicAngular({
            backButtonText: '',
            backButtonIcon: backIcon,
        }),
        ...environment.devProviders,
        { provide: LOCALE_ID, useValue: 'nl-BE' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: BASE_PATH, useValue: '' },
        { provide: Configuration, useFactory: () => new Configuration() },
        { provide: TitleStrategy, useClass: MbpTitleStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: true }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => initApp(inject(Sentry.TraceService))),
    ],
}).catch((err) => console.error(err));
