import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService, Configuration } from '@mbp/mbp-brainlane-vouchers-api-client';
import { catchError, from, map, tap } from 'rxjs';

import { environment } from '../../environments/environment';
import { ErrorService } from '../error.service';

export const ensureSession: CanActivateFn = (_, state) => {
    const config = inject(Configuration);
    const router = inject(Router);
    const authService = inject(AuthService);
    const errorService = inject(ErrorService);
    if (typeof config.credentials.sessionId === 'string') {
        return true;
    }
    const parsedUrl = new URL(state.url, window.location.origin);
    const token = parsedUrl.searchParams.get('token')!;
    if (!token) {
        return from(
            errorService.showErrorModal(
                'Error',
                'Authentication error',
                'The "token" query parameter was not set, cannot continue.',
                'Back',
            ),
        ).pipe(
            map(() => false),
            tap(() => environment.mbpClient.navigation.back()),
        );
    }
    parsedUrl.searchParams.delete('token');
    const returnUrl = state.url.split('?')[0] + parsedUrl.search;
    return authService.createAuthSession({ token }).pipe(
        tap((session) => (config.credentials = { sessionId: `Bearer ${session.sessionId}` })),
        map(() => router.createUrlTree([returnUrl], { queryParams: parsedUrl.searchParams })),
        catchError((err) =>
            from(
                errorService.showErrorModal('Fout', 'Fout tijdens inloggen', errorService.getMessage(err), 'Terug'),
            ).pipe(
                map(() => false),
                tap(() => environment.mbpClient.navigation.back()),
            ),
        ),
    );
};
