import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserService } from '@mbp/mbp-brainlane-vouchers-api-client';
import { Store } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { setCurrentUser } from '../brainlane-vouchers.actions';
import { selectCurrentUser } from '../brainlane-vouchers.selectors';
import { ErrorService } from '../error.service';
import { ensureSession } from './ensure-session.guard';

export const userExistsGuard: CanActivateFn = (route, state) => {
    const sessionResult = ensureSession(route, state);
    if (sessionResult !== true) {
        return sessionResult;
    }
    const userService = inject(UserService);
    const router = inject(Router);
    const store = inject(Store);
    const errorService = inject(ErrorService);
    return store.select(selectCurrentUser).pipe(
        switchMap((userResult) => {
            if (userResult) {
                return of(true);
            } else {
                return userService.getCurrentUser().pipe(
                    tap((user) => store.dispatch(setCurrentUser({ payload: user }))),
                    map(() => true),
                    catchError((err): Observable<boolean | UrlTree> => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 404) {
                                return of(router.createUrlTree(['/create-account']));
                            } else if (err.status === 401) {
                                return from(
                                    errorService.showErrorModal(
                                        'Error',
                                        'Kon niet inloggen',
                                        'Kon niet inloggen via single sign-on. Probeer later probeer opnieuw.',
                                        'Ok',
                                    ),
                                ).pipe(
                                    switchMap((modal) => from(modal.onDidDismiss())),
                                    switchMap(() => from(environment.mbpClient.navigation.back())),
                                    map(() => false),
                                );
                            }
                        }
                        return throwError(() => err);
                    }),
                );
            }
        }),
    );
};
