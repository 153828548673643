import { ChangeDetectionStrategy, Component, effect, inject, viewChild } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';

import { environment } from '../environments/environment';
import { selectCurrentUser } from './brainlane-vouchers.selectors';

@Component({
    selector: 'bv-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<ion-app><ion-router-outlet /></ion-app>',
    imports: [IonApp, IonRouterOutlet],
})
export class BrainlaneVouchersAppComponent {
    private routerOutlet = viewChild.required(IonRouterOutlet);
    private currentUser = inject(Store).selectSignal(selectCurrentUser);

    constructor() {
        environment.mbpClient.navigation.onBackNavigation((): boolean => {
            if (this.routerOutlet().canGoBack()) {
                this.routerOutlet().pop();
                return false;
            }
            return true;
        });
        effect(() => {
            const user = this.currentUser();
            Sentry.setUser(
                user
                    ? {
                          id: user.id,
                          email: user.email,
                          username: `${user.firstName} ${user.lastName}`,
                      }
                    : null,
            );
        });
    }
}
